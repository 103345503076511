









import { Component, Prop, Vue } from 'vue-property-decorator'

import Number from '@/components/_uikit/typography/Number.vue'

@Component({
  components: {
    Number,
  },
})
export default class GlassCard extends Vue {
  @Prop({
    default: '2022',
    validator (value: string) :boolean {
      return value.length < 6
    },
  })
  readonly year!: string
}
