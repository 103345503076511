





import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Number extends Vue {
  @Prop({
    default: 'div',
    validator (value: string): boolean {
      return !!value.match(/(span|p|div)/)
    },
  })
  readonly tag!: string
}
