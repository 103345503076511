
















import { Component, Vue } from 'vue-property-decorator'

//- COMPONENTS
import AuthFormWrapper from '@/components/AuthFormWrapper.vue'
import GlassCard from '@/components/cards/GlassCard.vue'
import IntermarkLabel from '@/components/IntermarkLabel.vue'

// STORE
import AuthModule from '@/store/modules/auth'
import ProfileModule from '@/store/modules/profile'

// INTERFACES
import { UserAuthResource } from '@/store/types'

@Component({
  components: {
    AuthFormWrapper,
    GlassCard,
    IntermarkLabel,
  },
})
export default class AuthLayout extends Vue {
  private get self (): UserAuthResource {
    return ProfileModule.userResource.user
  }

  private isReady = false

  private mounted () {
    // NOTE: Проверка должна быть на любом роуте авторизации, иначе при перезагрузке (на регистрации прочих) не появляется страница
    if (this.$route.name?.includes('auth')) {
      AuthModule.getUserResource()
        .then(() => {
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect.toString())
          } else {
            if (this.self) {
              this.$router.push({ name: 'role' })
            }
          }
        })
        .catch(() => {
          this.isReady = true
        })
    }
  }
}
